    :root {
    --background_color: #FAFAFA;
    --font: 'Poppins';
    }

    * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    }


    .navbar {
    width: 99vw;
    height: 10vh;

    display: flex;
    flex-direction: row;
    font-family: var(--font);

    gap: 14vw;
    padding: 4vh 5vw 3vh 5vw;

    }

    .nav_logo_container {
    width: 20vw;
    height: 8vh;
    }

    .nav_logo_container img {
    width: 100%;
    height: 100%;
    }

    .buttons_navbar {
    width: 18vw;
    height: 10vh;

    gap: 2vw;


    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    }

    .buttons_navbar select {
    width: 10vw;
    height: 3vh;

    font-family: "Plus Jakarta Sans";
    font-size: 1rem;
    font-weight: 700;
    line-height: 22.4px;
    letter-spacing: -0.02em;

    padding-left: 1vw;
    padding-right: 1vw;

    border: none;
    background-color: transparent;

    }

    .buttons_navbar select option {
    font-family: "Plus Jakarta Sans";
    font-size: 1rem;
    font-weight: 700;
    line-height: 22.4px;
    letter-spacing: -0.02em;

    padding-left: 1vw;
    padding-right: 1vw;

    }

    .buttons_navbar a {
    color: #010205;
    font-family: "Plus Jakarta Sans";
    font-size: 1rem;
    font-weight: 700;
    line-height: 22.4px;
    letter-spacing: -0.02em;

    text-decoration: none;

    }

    .buttons_navbar button {
    width: 10svw;
    height: 3vw;
    border-radius: 4vw;

    background-color: transparent;
    }

    .nav_links_container {

    width: 50vw;
    height: 10vh;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    }

    .navbar .nav_links_container button {
    display: flex;
    flex-direction: row;

    color: #020407;
    font-family: "Plus Jakarta Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration-line: none;

    background-color: transparent;
    border: none;
    cursor: pointer;

    }

    /* About me */
    .header {
    width: 99vw;
    height: 85vh;

    padding-top: 4vh;
    padding-left: 5vw;
    padding-right: 5vw;

    display: flex;
    flex-direction: row;
    gap: 6vw;

    margin-top: 2vh;
    margin-bottom: 5vh
    }



    .about_me_left {
    width: 44vw;
    height: 70vh;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    }

    .about_me_left h1 {
    color: #010205;
    font-family: Poppins;
    font-size: 4.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 4.95rem; 
    letter-spacing: -2.16px;
    }

    .about_me_left h2 {
    color: #878C91;
    font-family: "Plus Jakarta Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 180%; 

    padding-top: 3vh;
    padding-bottom: 3vh;

    }

    .about_me_right {
    width: 50vw;
    height: 70vh;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    }

    .about_me_right_top {
    display: flex;
    flex-direction: row;
    gap: 3vw;
    }

    .about_me_image {
    width: 22vw;
    height: 35vh;
    }

    .about_me_image img {
    width: 100%;
    height: 100%;
    /* border-radius: 1000px 20px 20px 20px; */

    }



    .about_me_text_experience {
    width: 18vw;
    height: 35vh;
    padding-top: 1.5vh;
    padding-left: 1.5vw;
    padding-right: 1.5vw;
    padding-bottom: 1.5vh;

    border-radius: 1.25rem;
    background: #FAFAFA;
    opacity: 0.9;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    }

    .circle_trend {
    width: 108px;
    height: 108px;
    border-radius: 683.544px;
    background: #010205;
    box-shadow: 0px 30.076px 50.582px -6.835px rgba(0, 0, 0, 0.44);

    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 30vh;
    left: 48vw;
    }

    .about_me_text_experience h2 {
    color: #010205;
    font-family: "Plus Jakarta Sans";
    font-size: 84px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: -2.52px;
    }


    .about_me_text_experience h3 {
    color: #5C5D5F;
    font-family: "Plus Jakarta Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; 

    padding-bottom: 15px;
    }

    .about_me_soluciones {

    position: relative;

    margin-top: 4vh;

    width: 100%;
    height: 28vh;

    border-radius: 20px;
    background: #010205;
    backdrop-filter: blur(42px);

    display: flex;
    flex-direction: column;
    }

    .about_me_soluciones_title {
    width: 19vw;
    height: 25vh;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;

    padding-left: 2vw;


    }

    .h2_soluciones_title {

    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    }

    .about_me_soluciones_title h2 {
    color: #FFF;

    font-family: "Plus Jakarta Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 126%; /* 17.64px */
    letter-spacing: -0.42px;
    }

    .h3_soluciones_text {
        display: flex;

        width: 20vw;
        height: 20vh;
    }

    .h3_soluciones_text h3 {
    color: #FFF;
    font-family: "Plus Jakarta Sans";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: -0.64px;
    }

    .about_me_soluciones_title h3,
    .about_me_soluciones_title {
    position: relative; 
    z-index: 1; 
    }

    .about_me_soluciones_images_background {

    height: 23vh;
    width: 19vw;

    position: absolute; 
    top: 0; 
    left: 0; 
    z-index: 0; 
    }

    .about_me_soluciones_images_background img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    }

    .rectangles_soluciones {
    width: 20vw;
    height: 25vh;

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;

    position: absolute;
    top: 3vh;
    left: 23vw;
    }

    .rectangle_1 {
    width: 5vw;
    height: 10vh;

    background: #BAE289;


    display: flex;
    flex-direction: column;
    }

    .rectangle_2 {
    width: 5vw;
    height: 15vh;

    background: #99CF63;


    display: flex;
    flex-direction: column;
    }

    .rectangle_3 {
    width: 5vw;
    height: 20vh;

    background: #77B248;


    display: flex;
    flex-direction: column;
    }



    .buttons_about_me {
    width: 25vw;
    height: 7vh;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 2vw;

    padding-top: 3vh;
    padding-bottom: 3vh;

    }

    .programar {
    width: 20vw;
    height: 7vh;

    border-radius: 70px;
    background: #010205;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    }

    .programar a {
    color: #FFF;
    font-family: "Plus Jakarta Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: -0.32px;

    text-decoration: none;
    }

    .linkedin {
    color: #010205;
    font-family: "Plus Jakarta Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 180%; /* 28.8px */
    text-decoration-line: underline;
    }

    .about_me_experience {

    height: 8vh;
    width: 40vw;

    display: flex;
    flex-direction: row;
    align-self: center;
    align-self: center;

    gap: 2vw;

    margin-top: 3vh;
    }

    .title_experience {
        width: 20vw;

        display: flex;
    }

    .title_experience h3 {
    color: #010205;
    font-family: "Plus Jakarta Sans";
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 22.4px */
    }



    .images_experience {
        display: flex;
        flex-direction: row;

        gap: 2vw;
    }

    .images_experience img {
    width: 100%;
    height: 60%;

    }

    .servicios {
    width: 92vw;
    height: 84vh;

    margin-top: 4vh;

    padding-top: 4vh;
    padding-left: 6vw;
    padding-right: 6vw;

    background-color: black;
    opacity: 0.9;

    position: relative;
    left: 3vw;

    border-radius: 1.875rem;
    z-index: 1;

    }

    .servicios_background {
    width: 92vw;
    height: 100%;

    opacity: 0.8;

    position: absolute;
    top: 0;
    right: 0;

    border-radius: 1.875rem;


    background-repeat: no-repeat;
    background-position: center;

    z-index: -1;

    }

    .servicios_background img {
    width: 100%;
    height: 100%;
    border-radius: 1.875rem;

    }

    .servicios_container {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    gap: 5vh;
    }


    .servicios_title {
    width: 96%;
    height: 10vh;

    display: flex;
    justify-content: center;
    align-items: center;

    }

    .servicios_title h2 {
    color: #FFF;
    text-align: center;
    font-family: "Plus Jakarta Sans";
    font-size: 3rem;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 3.9rem */
    letter-spacing: -0.09rem;
    }

    .servicios_nav_cards {
    width: 100%;
    height: 5vh;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1.5vw;




    }

    .servicios_nav_cards button {
    width: 25vw;
    height: 5vh;
    border-radius: 3rem;
    border: 1px solid #FFF;
    background: transparent;

    color: #FFF;
    font-family: "Plus Jakarta Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: -0.02rem;

    cursor: pointer;

    }

    .servicios_nav_cards button.active {
    background-color: #99CF63;
    color: #010205;
    }

    .servicios_cards {
    width: 100%;
    height: 50vh;

    gap: 5vw;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    }

    .servicios_card_image {
    width: 35vw;
    height: 50vh;
    }

    .servicios_card_image img {
    width: 94%;
    height: 90%;
    }

    .servicios_card_text {

    width: 60vw;
    height: 55hvh;

    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 4vh;



    border-radius: 1.875rem;
    border: 10px solid rgba(255, 255, 255, 0.30);
    backdrop-filter: blur(67px);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    }

    .servicios_card_title {
    color: #FFF;
    font-family: "Plus Jakarta Sans";
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 700;
    line-height: 126%; /* 1.33875rem */
    letter-spacing: -0.03188rem;

    padding-bottom: 3vh;


    }

    .servicios_card_list li {
        color: #FFF;
        font-family: "Plus Jakarta Sans";
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 1.4rem */

    }

    .servicios_card_list ul li {
    margin-bottom: 2vh;
    }


    .agendar {
    width: 99vw;
    height: 70vh;

    padding-top: 4vh;
    padding-left: 5vw;
    padding-right: 5vw;

    /* background-color: #99CF63; */

    display: flex;

    margin-top: 4vh;
    margin-bottom: 4vh;

    }

    .agendar_text{

    width: 89vw;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    padding-bottom: 6vh;

    }

    .agendar_text h2 {


    color: #010205;
    font-family: "Plus Jakarta Sans";
    font-size: 3rem;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 3.9rem */
    letter-spacing: -0.09rem;

    padding-right: 24vw;


    }

    .agendar_text p {

    color: #878C91;
    font-family: "Plus Jakarta Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 180%; /* 1.8rem */
    width: 75vw;

    }

    .agendar_images {
    width: 90vw;
    height: 40vh;


    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    gap: 3vh;

    }

    .left_text_agendar {
    width: 35vw;
    height: 40vh;

    border-radius: 1.875rem;
    background: #010205;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 3vh;

    }


    .left_text_agendar p {
    color: #FFF;
    font-family: "Plus Jakarta Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 170%; /* 1.7rem */
    }

    .right_text_agendar {

    width: 55vw;
    height: 40vh;

    border-radius: 1.875rem;
    background: #010205;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    background-image: url("../public/agendar_image.png");
    background-repeat: no-repeat;

    padding: 8;

    }

    .right_text_agendar h2 {


    color: #FFF;
    text-align: center;
    font-family: "Plus Jakarta Sans";
    font-size: 3rem;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 4.2rem */
    letter-spacing: 0.81rem;
    }

    .schedule_buttons {

    width: 16vh;
    height: 16vh;

    border-radius: 64.47788rem;
    border: 12px solid #FAFAFA;
    background: #99EA48;

    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
    top: 5vh;
    left: 23.5vw;

    }

    .certificados {
    width: 90vw;

    padding-top: 4vh;
    padding-left: 5vw;
    padding-right: 5vw;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    padding-bottom: 5vh;
    }

    .carousel {
    width: 99vw;
    height: 50vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding-top: 4vh;
    padding-left: 5vw;
    padding-right: 5vw;

    margin-top: 4vh;
    margin-bottom: 5vh;

    }

    .review_card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2vh;
    padding: 2vh;
    }

    .review_text {

    color: #010205;
    font-family: "Plus Jakarta Sans";
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.2rem;
    letter-spacing: -0.0675rem;
    }

    .review_author_card{

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    gap: 2vh;

    position: relative;
    left: -30vw;
    }

    .review_author_avatar {
    width: 5vw;
    height: 5vw;
    }

    .review_author_avatar img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    }

    .review_author h4 {
    color: #010205;
    font-family: "Plus Jakarta Sans";
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.25rem;
    }

    .review_author p {
    color: #878C91;
    font-family: "Plus Jakarta Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.8rem;
    }

    .review_author img {
    width: 5vw;
    height: 5vw;
    border-radius: 1000px;
    }

    .carousel_dots {
    height: 5vh;
    width: 20vw;

    position: relative;
    top: -7vh;
    left: 35vw;

    display: flex;
    justify-content: space-between;
    align-items: center;
    }

    .carousel_dots button {
    width: 8vw;
    height: 2.5vw;
    border-radius: 4rem;
    background: #FFF;
    }

    .carousel_dots button:hover {
    background: #010205;
    color: white;
    }


    /* Faqs */

    .faqs {
    width: 99vw;

    padding-top: 4vh;
    padding-left: 5vw;
    padding-right: 5vw;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    padding-bottom: 5vh;

    margin-top: 4vh;

    gap: 5vw;

    }

    .faqs_text {

    width: 50vw;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    gap: 2vh;

    padding: 2vh;
    }

    .faqs_text h2 {

    color: #010205;
    font-family: "Plus Jakarta Sans";
    font-size: 3rem;
    font-style: normal;
    font-weight: 600;
    line-height: 3.9rem;
    letter-spacing: -0.09rem;

    }

    .faqs_text p {

    color: #878C91;
    font-family: "Plus Jakarta Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 180%; /* 1.8rem */

    }

    .faqs_questions  {
    list-style: none;

    display: flex;
    flex-direction: column;
    gap: 2vh;

    width: 55vw;

    }

    .faqs_questions h2 {
        font-family: "Plus Jakarta Sans";
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: -0.03em;
    text-align: left;

    }

    .pregunta hr {
        height: 0.3vh;
        background-color: #010205;

        margin-top: 2vh;
        margin-bottom: 2vh;

    }

    .pregunta {
    display: flex;
    flex-direction: column;


    }

    .pregunta button {
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
    text-align: left;

    color: #010205;
    font-family: "Plus Jakarta Sans";
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;

    


    }

    .pregunta.active .respuesta {
    display: block;
    }

    .respuesta {
    color: #878C91;
    font-family: "Plus Jakarta Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 180%; /* 1.8rem */

    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 2vh;
    padding-bottom: 2vh;

    }


    .blog {
    width: 99vw;
    height: 75vh;

    /* padding-top: 4vh;  */
    padding-left: 5vw;
    padding-right: 5vw;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding-bottom: 5vh;

    margin-top: 4vh;

    background-color: #FAFAFA;
    opacity: 0.9;




    }

    .blog_title {


    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    gap: 5vw;

    width: 90vw;
    height: 30vh;

    padding-left: 3vw;
    padding-right: 3vw;


    }


    .blog_title h2 {
    color: #010205;
    font-family: "Plus Jakarta Sans";
    font-size: 3rem;
    font-style: normal;
    font-weight: 600;
    line-height: 3.9rem;
    letter-spacing: -0.09rem;
    }

    .subtitle_blog {

    width: 60vw;
    height: 15vh;

    }

    .subtitle_blog p {
    color: #878C91;
    font-family: "Plus Jakarta Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.8rem;
    }


    .subtitle_blog button {
    width: 10vw;
    height: 5vh;

    margin-top: 3vh;
    padding: 1rem 1rem;

    background-color: transparent;

    border-radius: 3.125rem;
    border: 1px solid #010205;

    display: flex;
    justify-content: center;
    align-items: center;

    color: #010205;
    font-family: "Plus Jakarta Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.4rem;
    letter-spacing: -0.02rem;


    }

    .blog_carousel{
    width: 95vw;
    height: 40vh;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    gap: 3vw;

    padding-top: 4vh;
    padding-bottom: 4vh;
    padding-left: 5vw;
    padding-right: 5vw;


    }

    .blog_carousel_blog {
        width: 95vw;
        height: 40vh;
    
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    
        gap: 3vw;
    
        padding-top: 4vh;
        padding-bottom: 4vh;
        padding-left: 5vw;
        padding-right: 5vw;
    }

    .blog_card {
    width: 22vw;
    height: 32vh;
    border-radius: 1.25rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;   

    gap: 3vh;

    background-color: #FFFFFF;

    padding-left: 1vw;
    padding-right: 1vw;

    }

    .point_blog {
    width: 1vw;
    height: 1vw;
    border-radius: 1000px;
    background: #99CF63;
    }

    .blog_card_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    }

    .time_read h3 {
    overflow: hidden;
    color: #878C91;
    text-overflow: ellipsis;
    font-family: "Plus Jakarta Sans";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 1.4rem */
    }

    .blog_card_title h3 {
    color: #010205;
    font-family: "Plus Jakarta Sans";
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 2.4375rem */
    letter-spacing: -0.04875rem;
    }

    .blog_card_body {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    }

    .blog_card_body p {
    color: #878C91;
    text-overflow: ellipsis;
    font-family: "Plus Jakarta Sans";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 1.4rem */
    }

    .blog_card_body button {
    width: 3vw;
    height: 5vh;
    border-radius: 4.375rem;
    background: transparent;
    cursor: pointer;
    }


    .cta {
    width: 94vw;
    height: 18svh;

    padding-top: 4vh;
    padding-bottom: 4vh;
    padding-left: 5vw;
    padding-right: 5vw;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;


    border-radius: 1.875rem;
    background: #020609;

    position: relative;
    left: 3vw;

    margin-top: 8vh;
    }

    .cta h2 {
    color: #FFF;
    font-family: "Plus Jakarta Sans";
    font-size: 4.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 6.5rem */
    letter-spacing: -0.15rem;
    }

    .cta button {

    margin-left: 3vw;

    display: flex;
    padding: 1rem 2rem;
    justify-content: center;
    align-items: center;

    border-radius: 4.375rem;
    background: #FFF;

    cursor: pointer;

    color: #010205;
    font-family: "Plus Jakarta Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 1.4rem */
    letter-spacing: -0.02rem;
    }

    .footer_container {
    width: 99vw;
    height: 30vh;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    padding-top: 2vh;
    padding-bottom: 2vh;
    padding-left: 2vw;
    padding-right: 2vw;

    gap: 5vw;

    margin-top: 4vh;


    }

    .footer_container h3 {
    color: #192031;
    font-family: "Plus Jakarta Sans";
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.01125rem;
    }

    .footer_container a {
    color: #9B9B9C;
    font-family: "Plus Jakarta Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.0175rem;
    text-decoration-line: none;

    display: flex;
    flex-direction: row;
    gap: 1.2vw;
    }

    .footer_container p {
    color: #9B9B9C;
    font-family: "Plus Jakarta Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.0175rem;
    text-decoration-line: none;

    display: flex;
    flex-direction: row;
    gap: 1.2vw;
    }

    .footer_logo {
    width: 15vw;
    height: 10vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: 6vh;
    }

    .footer_logo img {
    width: 90%;
    height: 80%;
    }

    .footer_navegacion {

    width: 12vw;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    gap: 2vh;
    }

    .footer_navegacion button {
        background-color: transparent;
        border: none;
        cursor: pointer;

        font-family: "Plus Jakarta Sans";
        font-size: 14px;
        font-weight: 400;
        line-height: 16.41px;
        letter-spacing: -0.02em;
        text-align: left;

    }


    .footer_licence {

    width: 12vw;


    display: flex;
    flex-direction: column;

    gap: 2vh;

    }

    .footer_social {

    width: 20vw;


    display: flex;
    flex-direction: column;

    gap: 2vh;

    }

    .coopiright {



        height: 5vh;

    }





    .footer_redes {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    gap: 2vw;

    margin-bottom: 4vh;
    }

    .article_blog_container {
        margin-top: 5vh;
        margin-bottom: 5vh;
        width: 99vw;
        height: auto;

        padding: 5vh;

        background-color: #FAFAFA;
        opacity: 0.9;
    }

    .article_blog_title {
        width: 90vw;
        height: 20vh;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        gap: 2vh;
    }

    .title_blog_header h1 {
        color: #010205;
        font-family: "Plus Jakarta Sans";
        font-size: 3rem;
        font-style: normal;
        font-weight: 600;
        line-height: 130%; /* 3.9rem */
        letter-spacing: -0.09rem;
    }

    .article_blog_subtitle p {
        color: #878C91;
        font-family: "Plus Jakarta Sans";
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 180%; /* 1.8rem */
    }

    .article_blog_body {
        width: 90vw;
        height: auto;

        display: flex;
        justify-content: center;
        align-items: flex-start;

        gap: 5vw;
        margin-top: 4vh;
        margin-bottom: 10vh;
    }

    .article_blog_body h3 {
        color: #010205;
        font-family: "Plus Jakarta Sans";
        font-size: 2.3rem;
        font-style: normal;
        font-weight: 600;
        line-height: 160%;
        letter-spacing: -0.05625rem;
    }

    .article_blog_body p {
        color: #010205;
        font-family: "Plus Jakarta Sans";
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 600;
        line-height: 5.5vh; /* 2rem */
        letter-spacing: -0.0375rem;
        padding: 6vh;

    }


    .suggest_blog_articles h2 {
        color: #010205;
        font-family: "Plus Jakarta Sans";
        font-size: 3rem;
        font-style: normal;
        font-weight: 600;
        line-height: 130%; /* 3.9rem */
        letter-spacing: -0.09rem;
    }

    @media screen and (max-width: 800px) {


    .nav_links_container {
    display: none;
    }

    .nav_logo_container {
    width: 44vw;
    }

    .buttons_navbar {
    flex-direction: column;
    width: 30vw;

    }

    .buttons_navbar button {
    width: 30vw;
    height: 5vh;
    }

    .buttons_navbar select {
    width: 30vw;
    height: 5vh;
    background-color: transparent;
    }

    .header {
    flex-direction: column-reverse;
    height: 155vh;
    }

    .about_me_right_top {
    width: 90vw;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    }

    .about_me_image {
    width: 55vw;
    height: 24vh;
    }	

    .circle_trend {
    top: 32vh;
    left: 0vw;

    width: 8vh;
    height: 8vh;
    }	

    .about_me_soluciones {
        height: 24vh;
    }

    .rectangles_soluciones  {
        height: 21vh;
    }

    .about_me_experience {
    width: 85vw;
    height: 8vh;
    gap: 0vw;

    }

    .about_me_experience h3 {
    font-size: 1rem;
    }

    .about_me_text_experience {
    width: 45vw;
    height: 24vh;
    
    }

    .about_me_text_experience h2 {
    font-size: 4rem;
    }	

    .about_me_text_experience p {
    font-size: 1rem;
    }	

    .about_me_text_experience_line {
        display: none;

    }

    .about_me_soluciones {
    width: 90vw;
    }	

    .about_me_soluciones_title {
    width: 50vw;
    }	

    .h3_soluciones_text h3 {
    font-size: 1.5rem;
    }

    .h3_soluciones_text {
        width: 45vw;
        height: 20vh;
    }

    .about_me_left {
    flex-direction: column;
    width: 90vw;
    margin-top: 2vh;
    height: 90vh;
    }

    .about_me_left h1 {
    font-size: 3.125rem;
    }	

    .about_me_text_experience h3 {
    font-size: 0.8rem;
    }

    .rectangles_soluciones {
    width: 40vw;
    left: 50vw;
    }

    .rectangle_1, .rectangle_2, .rectangle_3 {
    width: 10vw;
    }



    .buttons_about_me {
    display: flex;
    justify-content: space-around;

    width: 90vw;
    height: 20vh;
    }

    .programar {
    width: 60vw;
    }

    .servicios {
    margin-top: 8vh;
    margin-bottom: 5vh;
    height: 180vh;

    }

    .servicios_title  {
    margin-top: 5vh;
    }

    .servicios_title h2 {
    font-size: 1.5rem;
    }

    .servicios_nav_cards {
    flex-direction: column;
    margin-top: 1vh;
    gap: 2vh;

    height: 20vh;

    margin-bottom: 5vh;
    }

    .servicios_nav_cards button {
    width: 70vw;
    height: 10vh;
    }

    .servicios_cards {
        display: flex;
        flex-direction: column;
        height: auto;
    }


    .servicios_card_image {
    width: 60vw;
    height: 35vh;
    }

    .servicios_card_text {
    width: 85vw;
    height: 85vh;
    }

    .servicios_card_title {
    font-size: 1rem;
    }

    .servicios_card_list {
    font-size: 1rem;
    margin-left: 5vw;
    }

    .agendar {
    height: 155vh;
    }

    .agendar_text {
    flex-direction: column;
    width: 89vw;
    }

    .agendar_text h2 {
    font-size: 2.5rem;

    padding: 3vh;
    }

    .agendar_text p {
    font-size: 1rem;
    padding: 3vh;

    }

    .agendar_images {
    flex-direction: column;
    width: 90vw;
    height: 60vh;
    }
    
    .images_experience img {
    width: 100%;
    height: 60%;
    }

    .left_text_agendar {
    width: 90vw;
    height: 48vh;

    font-size: 0.875rem;
    }

    .right_text_agendar {
    width: 90vw;
    height: 48vh;

    font-size: 0.875rem;

    display: flex;
    }

    .right_text_agendar h2 {
    font-size: 1.5rem;
    color: #FFF;
    text-align: center;
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 600;
    line-height: 2rem;
    letter-spacing: 0.45706rem;
    }


    .carousel {
    margin-top: 80vh;
    }

    .carousel {
    display: none;
    }

    .faqs {
    flex-direction: column;
    height: 100vh;
    width: 99vw;
    height: 180vh;
    padding: 4vh 5vw 3vh 5vw;
    margin-top: 12vh;
    }


    .faqs_text {
    width: 90vw;
    height: 50vh;
    }

    .faqs_text h2 {
    font-size: 3rem;
    }

    .faqs_text p {
    font-size: 1.3rem;
    }

    .faqs_questions {
    width: 100%;
    }




    .blog {
    flex-direction: column;
    margin-top: 30vh;
    height: 150vh;
    }

    .blog_title {
    flex-direction: column;
    width: 90vw;
    height: 30vh;
    }

    .blog_title h2 {
    color: #010205;
    font-family: "Plus Jakarta Sans";
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.4rem;
    letter-spacing: -0.05625rem;
    }

    .blog_title p {
    color: #878C91;
    font-family: "Plus Jakarta Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 180%; /* 1.8rem */
    }

    .blog_carousel {
    flex-direction: column;
    margin-top: 20vh;
    height: 100vh;
    }

    .blog_card {
    width: 90vw;
    height: 32vh;
    }

    .point_blog {
    width: 4vw;
    height: 4vw;
    }

    .blog_card_body button {
    width: 30vw;
    }


    .cta {
    flex-direction: column;
    margin-top: 5vh;
    width: 94vw;
    height: 25vh;
    }

    .cta h2 {
        color: #FFF;
        font-family: "Plus Jakarta Sans";
        font-size: 1.8rem;
        font-style: normal;
        font-weight: 600;
        line-height: 130%; /* 2.4375rem */
        letter-spacing: -0.05625rem;
        
    }

    .cta button {
        color: #010205;
    font-family: "Plus Jakarta Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 1.4rem */
    letter-spacing: -0.02rem;

    cursor: pointer;
    }

    .footer_container {
        display: flex;
    flex-direction: column;
    margin-top: 15vh;
    width: 100vw;
    }

    .footer_logo {
        width: 70vw;
        height: 10vh;
    }

    .footer_navegacion {
        width: 70vw;
        height: 20vh;

        display: flex;
        flex-direction: column;
        align-items: center;

        font-family: "Plus Jakarta Sans";
        font-size: 14px;
        font-weight: 400;
        line-height: 16.41px;
        letter-spacing: -0.02em;
        text-align: left;
        
    }

    .footer_navegacion button {
        background-color: transparent;
        border: none;
        cursor: pointer;

        font-family: "Plus Jakarta Sans";
        font-size: 14px;
        font-weight: 400;
        line-height: 16.41px;
        letter-spacing: -0.02em;
        text-align: left;
    }

    .footer_social {

        width: 70vw;
        height: 20vh;

        display: flex;
        flex-direction: column;
        align-items: center;

    }

    .footer_social h3 {
        font-family: "Plus Jakarta Sans";
        font-size: 1.3rem;
        font-weight: 600;
        line-height: 21.09px;
        letter-spacing: -0.01em;
        text-align: left;

    }

    .footer_navegacion p {
        font-family: "Plus Jakarta Sans";
        font-size: 1rem;
        font-weight: 400;
        line-height: 16.41px;
        letter-spacing: -0.02em;
        text-align: left;
    }

    .footer_navegacion h3 {
        font-family: "Plus Jakarta Sans";
        font-size: 1.3rem;
        font-weight: 600;
        line-height: 21.09px;
        letter-spacing: -0.01em;
        text-align: left;
    }

    .coopiright {

        display: none;
        
        width: 60vw;
        height: 10vh;

        display: flex;
        flex-direction: column;
        align-items: center;

        top: 980vh;
    }

    .coopiright_footer {
        
        width: 60vw;
        height: 10vh;

        display: flex;
        flex-direction: column;
        align-items: center;


        position: relative;
        top: 3vh;
        left: 3vw;
    }

    .coopiright p {
        font-family: "Plus Jakarta Sans";
        font-size: 1rem;
        font-weight: 400;
        line-height: 16.41px;
        letter-spacing: -0.02em;
        text-align: left;

    }

   .article_blog_container {
    margin-top: 5vh;
   }

   .article_blog_title {
    width: 85vw;
    height: 10vh;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    gap: 2vh;
   }

   .title_blog_header h1 {
    font-size: 1.3rem;
   }

   .article_blog_subtitle p {
    font-size: 0.8rem;
   }

   .article_blog_body {
    height: auto;
    width: 85vw;
   }

   .suggest_blog_articles {

    margin-top: 5vh;
    width: 85vw;
    height: auto;
   }

   .blog_carousel_blog {
    margin-top: 5vh;
    width: 85vw;
    height: auto;

    display: flex;
    flex-direction: column;
   }

   
   .footer_logo img {
    width: 70%;
    height: 80%;
    }

    .title_experience {
        width: 25vw;
    }

    .title_experience h3 {
        font-size: 0.8rem;
    }

    .images_experience {
        width: 16vw;

    }

    .navbar {
        height: 17vh;
    }

    .article_blog_body p {
        padding: 2vh;
        font-size: 1rem;
    }
   
    
    }
